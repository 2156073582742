import React from "react";
import {useUser} from "../../../../../Context/User";
import { colors } from "../../../../../Utils/Constants";
import IconButton from "../../../IconButton";
import {Container,Row,Col} from "react-bootstrap";
const Content = ()=>{ 
    const {Seats,seats} = useUser();
    const seatContainerStyle = {
        width:window.screen.availWidth>=500?100:50,
        height:window.screen.availWidth>=500?100:50,
        borderRadius:20,
        backgroundColor:colors.primary,
        borderStyle:"solid",
        borderWidth:2,
        borderColor:colors.bgGrayDark,
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    }
    return(
        <Container>
            <Row>
                <Col xs="4" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <IconButton icon="FaMinus" type="info" execute={()=>Seats.subtractSeat(1)} title="Eliminar un asiento" disabled={seats<1?true:false} round/>
                </Col>
                <Col xs="4" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <div style={seatContainerStyle}>
                        <span style={{fontWeight:"bold",fontSize:20,color:colors.white}}>
                            {seats}
                        </span>
                    </div>
                </Col>
                <Col xs="4" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <IconButton icon="FaPlus" type="info" execute={()=>Seats.addSeat(1)} title="Aumentar un asiento" round/>
                </Col>
            </Row>
        </Container>
    )
}

export default Content;