import React from "react";
import Loader from 'react-loader-spinner';
import {colors} from "../../Utils/Constants";

export default function Load(props){
  const {isVisible,size,color} = props;
  const URLIMGLOGOWHITE = `${process.env.PUBLIC_URL}/img/john_milton_logo_white.png`;
  if(isVisible){
    return (
      <div style={{width:"100%"}} className="d-flex justify-content-center">
        <div>
          <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <Loader
              type={"ThreeDots"}
              color={color||colors.secondary}
              height={size||150}
              width={size||150}
              visible={true}
            />
          </div>
          <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <img src={URLIMGLOGOWHITE} alt="JM-LOGO" className="jm-logo" />
          </div>
        </div>
      </div>
    )
  }else{
    return(<></>);
  }
}
