import React,{useState,useEffect} from "react";
import {TicketsProvider,useTickets} from "./Context";
import {useUser} from "../../../../../Context/User";
import FadeIn from 'react-fade-in';
import Content from "./Content";
import AloneLoader from "../../../AloneLoader";
import ErrorContainer from "../../../ErrorContainer";

const TicketsController = (props)=>(
    <FadeIn transitionDuration={800}>
        <TicketsProvider>
            <TicketsTunnel Content={()=><Content />} />
        </TicketsProvider>
    </FadeIn>
)
export default TicketsController;

const TicketsTunnel = (props)=>{
    const {Content} = props;
    const {isLoading,data,error} = useTickets();
    return(
        <>
            {
                isLoading ? <AloneLoader isVisible={true} size={100} /> : error? <ErrorContainer error={error} /> : data ? <Content /> : <p>...</p> 
            }
        </>
    )
}