import React,{useState} from "react";
import {Container,Row,Col} from "react-bootstrap";
import IconButton from "../IconButton";
import {colors,errorTagStyle} from "../../../Utils/Constants";
import {FaHandPointUp} from "react-icons/fa";
import FadeIn from 'react-fade-in';
import {useUser} from "../../../Context/User";
import Modal from "../Modal";
import ContentModalRegister from "./ContentModalRegister";
import LoginContent from "../LoginModal/Content";
import LoginModal from "../LoginModal";

const LoginComponent = (props)=>{
    const { rightPanel } = props;
    return(
        <FadeIn transitionDuration={800}>
            {
                rightPanel ? 
                <>
                    <div className="d-flex justify-content-end">
                        <span style={{color:colors.secondary,fontWeight:"bold",fontSize:10}}>
                            Iniciar Sesión
                        </span>  
                    </div>
                </> : null
            }
            <LoginModal dontUseModal={true} />
            {
                rightPanel ? 
                <>
                    <div style={errorTagStyle} className="d-flex justify-content-center">
                        <div style={{display:"flex",flexDirection:"row",maxWidth:300}}>
                            <div style={{width:"30%"}} className="alineado">
                                {
                                    <FaHandPointUp style={{fontSize:60,color:colors.secondary}} />
                                }
                            </div>
                            <div style={{width:"70%",textAlign:"center"}}>
                                <span style={{fontWeight:"bold",fontSize:20,color:"dimgrey"}} className="alineado">
                                    Inicia sesión o registrate para continuar.
                                </span>
                            </div>
                        </div>
                    </div>
                </> : null
            }
        </FadeIn>
    )
}
export default LoginComponent;