import { Typography } from "@mui/material";
import { Row,Col,Container } from "react-bootstrap";
import { useLoginContext } from "./store";
import Input from "../../Input";
import { colors } from "../../../../Utils/Constants";
import {FaCircle,FaCheckCircle} from "react-icons/fa";
import IconButton from "../../IconButton";
const RegisterForm = ()=>{
    const {
        LoginData,
        names,setNames,checkNames,
        second_names,setSecond_names,checkSecondNames,
        telefono,setTelefono,checkTelefono,
        selectedEstado,setSelectedEstado,
        email,setEmail,checkMail,
        psw,setPsw,checkPsw,
        psw2,setPsw2,checkPsw2,
        terminos,setTerminos,
        checkFormErrorsRegister,getErrors,
        reset,register,loadRegister,
        func
    } = useLoginContext()
    //console.log("LOGINDATA IN REGISTERFORM",LoginData)
    const {states} = LoginData;
    return(
        <Container>
            <Row>
                <Col xs="12">
                    <Input value={names} onChange={setNames} errorMsg={checkNames()} placeholder="Nombre(s)" label="Nombre(s)" />
                </Col>
                <Col xs="12">
                    <Input value={second_names} onChange={setSecond_names} errorMsg={checkSecondNames()} placeholder="Apellido(s)" label="Apellido(s)" />
                </Col>
                <Col xs="12">
                    <Input value={telefono} onChange={setTelefono} errorMsg={checkTelefono()} placeholder="Teléfono" label="Teléfono (10 digitos)" />
                </Col>
                <Col xs="12">
                    <label style={{color:"#fff",fontWeight:"bold",fontSize:12,marginLeft:5}}>
                        Seleccione una estado
                    </label>
                    <select className="form-control" value={selectedEstado} onChange={(el)=>setSelectedEstado(el.target.value)}>
                        <option value="">
                            Seleccione una estado
                        </option>
                        {
                            states.map((item,index)=>(
                                <option key={index} value={item.value}>
                                    {
                                        item.label
                                    }
                                </option>
                            ))
                        }
                    </select>
                </Col>
                <Col xs="12">
                    <Input value={email} onChange={setEmail} errorMsg={checkMail()} placeholder="Correo electrónico" label="Email" />
                </Col>
                <Col xs="12">
                    <Input value={psw} onChange={setPsw} errorMsg={checkPsw()} placeholder="Contraseña" label="Contraseña" password />
                </Col>
                <Col xs="12">
                    <Input value={psw2} onChange={setPsw2} errorMsg={checkPsw2()} placeholder="Contraseña" label="Repetir Contraseña" password />
                </Col>
                <Col xs="12" className="p-4">
                    <Row>
                        <Col xs="2">
                            {
                                terminos ? 
                                <FaCheckCircle onClick={()=>setTerminos(!terminos)} style={{fontSize:25,color:colors.secondary,cursor:"pointer"}} /> 
                                :
                                <FaCircle onClick={()=>setTerminos(!terminos)} style={{fontSize:25,color:colors.gray,cursor:"pointer"}} />
                            }
                        </Col>
                        <Col xs="10">
                            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                <a target="_blank" href="/terminos" style={{fontWeight:"bold",fontSize:12,color:terminos?colors.secondary:colors.gray,textAlign:"center",marginTop:5}} className="alineado">
                                Acepto términos y condiciones de uso de la aplicación.
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Col>
                {
                    !checkFormErrorsRegister() ? 
                    <Col xs="12" className="p-1">
                        <div style={{borderRadius:10,backgroundColor:colors.primary,padding:5,borderStyle:"solid",borderWidth:2,borderColor:colors.secondary}}>
                            {
                                getErrors().map((err,key)=>key===0?(
                                    <div className="d-flex justify-content-center mt-2">
                                        <p style={{color:colors.gray,fontSize:10,fontWeight:"bold"}}>
                                            AYUDA:"{err}"
                                        </p>
                                    </div>
                                ):null)
                            }
                        </div>
                    </Col> : null
                }
                <Col xs="6" className="d-flex justify-content-center mt-4">
                    <IconButton icon="FaSyncAlt" type="info" execute={reset} title="Limpiar formulario" round />
                </Col>
                <Col xs="6" className="d-flex justify-content-center mt-4">
                    <IconButton icon="FaUserPlus" type="info" execute={register} loader={loadRegister} title="Registrarme" disabled={!checkFormErrorsRegister()} round />
                </Col>
                
                <Col xs="8" style={{marginTop:15}}>
                    <Typography
                        style={{
                            textAlign:"center",
                            color:colors.gray,
                            fontSize:12,
                            cursor:"no-drop"
                        }}
                    >
                        ¿Ya tienes una cuenta?
                    </Typography>
                </Col>
                <Col xs="4" style={{marginTop:15}}>
                    <Typography
                        onClick={func.showLogin}
                        style={{
                            textAlign:"center",
                            color:colors.secondary,
                            fontSize:12,
                            cursor:"pointer"
                        }}
                    >
                        Iniciar Sesión
                    </Typography>
                </Col>
            </Row>

        </Container>
    )
}
export default RegisterForm;