import React,{useState,useEffect} from "react";
import {AreaSelectorProvider,useAreaSelector} from "./Context";
import {useUser} from "../../../../../Context/User";
import FadeIn from 'react-fade-in';
import Content from "./Content";
import AloneLoader from "../../../AloneLoader";

const AreaSelector = (props)=>{
    const {selectedDate} = useUser();
    const [functions,setFunctions] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const handleLoading = (param)=>setIsLoading(param);




    return(
        <>
            <FadeIn transitionDuration={800}>
                <AreaSelectorProvider id_clave={selectedDate}>
                    <AreaSelectorTunnel Content={()=><Content />} />
                </AreaSelectorProvider>
            </FadeIn>
        </>
    )
}
export default AreaSelector;

const AreaSelectorTunnel = (props)=>{
    const {Content} = props;
    const {isLoading} = useAreaSelector();
    return(
        <>
            {
                isLoading ? <AloneLoader isVisible={true} size={100} /> : <Content />
            }
        </>
    )
}