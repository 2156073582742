import React,{useState,useEffect} from "react";
import {useUser} from "../../../../../Context/User";
import FadeIn from 'react-fade-in';
import Content from "./Content";
import {colors} from "../../../../../Utils/Constants";

const SeatSelector = (props)=>{
    const {selectedFunction} = useUser();
    const [functions,setFunctions] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const handleLoading = (param)=>setIsLoading(param);




    return(
        <>
            <FadeIn transitionDuration={800}>
                <div className="d-flex justify-content-center">
                    <span style={{color:colors.white,fontWeight:"bold",fontSize:15}}>
                        Número de asientos
                    </span>  
                </div>
                <Content />
            </FadeIn>
        </>
    )
}
export default SeatSelector;