import React from "react";
import { colors } from "../../Utils/Constants";
import Loader from 'react-loader-spinner';

const ScreenLoader = ({visible})=>{
    if(visible){
        return(
            <div className="alineado" style={{position:"fixed",top:0,left:0,width:"100vw",height:"100vh",backgroundColor:"rgba(56, 56, 56, 0.5)",zIndex:9999}}>
                <div>
                    <div className="d-flex justify-content-center">
                        <img 
                            src={`${process.env.PUBLIC_URL}/img/john_milton_logo.png`}
                            style={{maxWidth:300}}
                        />
                    </div>
                    <div className="d-flex justify-content-center">
                        <Loader
                            type={"ThreeDots"}
                            color={colors.secondary}
                            height={150}
                            width={150}
                            visible={true}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
export default ScreenLoader;