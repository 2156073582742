import React,{Fragment} from "react";
//Content App imports
//Libraries imports
import {ToastContainer} from "react-toastify";
//App context
import {UserProvider} from "./Context/User";
import NAVIGATION from "./Navigation";
import DevPannel from "./Components/Elements/DeveloperPannel";
const useDevPannel = false;
const App = ()=>{
  return (
    <Fragment>
      <UserProvider>
        <div className="App">
            <NAVIGATION />
            <ToastContainer position="bottom-center" autoClose={5000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover/>
            {process.env.REACT_APP_DEV === "true" && useDevPannel ? <DevPannel /> : null}
        </div>
      </UserProvider>
    </Fragment> 
  );
}

export default App;
