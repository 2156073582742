import React, { Fragment } from "react";
import Content from "./Content";
const Home = ({navigation})=>(<Fragment>
    <Content />
</Fragment>);
export default Home;

/*
{
    return(
        <Fragment>
            <LeftBar />
            <RightBar />
            <IconNav />
            <ModalController />
            <Welcome />
            <DevPannel />
        </Fragment>
    )
}
*/