import React, { Fragment } from "react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";
import { useTickets } from "../../Context";
import { Container } from "react-bootstrap";
import { colors } from "../../../../../../../Utils/Constants";
import { useConfirmation } from "../Store";
const PKSTRIPE_PROD = 'pk_live_51IlLS6LO8EvrqqDZ4TnS99242RvpmRPoGWG4MIDvcirq2lzMDKszvxNj0y1N821Ko48Z7MFMKXXaUtVzfinnTW3P00KWWLQ0KT';
const PKSTRIPE_DEV = 'pk_test_gLGU398IN6dfa93LBSOzPunL00so3QuDJO';
const PKSTRIPE = process.env.REACT_APP_DEV === "true" ? PKSTRIPE_DEV : PKSTRIPE_PROD;
const stripePromise = loadStripe(PKSTRIPE);
const StripeForm = ()=>{
    const {clientSecret} = useConfirmation();
    if(clientSecret){
        console.log("PKSTRIPE:",PKSTRIPE);
        const options = {
            // passing the client secret obtained from the server
            clientSecret,
        };
        return(
            <Container className="d-flex justify-content-center">
                <div style={{
                    width:"90%",
                    backgroundColor:colors.bgGrayDark,
                    padding:20,
                    marginTop:10,
                    border:`solid 1px ${colors.primary}`,
                    borderRadius:10
                }}>
                    <Elements stripe={stripePromise} options={options}>
                        <CheckoutForm />
                    </Elements>
                </div>
            </Container>
        )
    }
    return null;
}
export default StripeForm;