import React from "react";
import { colors } from "../../../Utils/Constants";
import "./styles.css"
const Load = (props)=>{
  const URLIMGLOGOWHITE = `${process.env.PUBLIC_URL}/img/john_milton_logo_white.png`;
  const {isVisible} = props;
  if(isVisible)return <div className="loadContainer" style={{
    backgroundColor:colors.secondary
  }}>
    <div className="loadContainer-img">
      <img src={URLIMGLOGOWHITE} alt="JM-LOGO" className="jm-logo" />
    </div>
  </div>
  return(null);
}
export default Load;
