import Load from "../../Components/Elements/AloneLoader";
import ErrorContainer from "../../Components/Elements/ErrorContainer";
import Header from "../../Components/Elements/Header";
import ContentConfig from "../../Components/Elements/LeftBar/UserData/ModalContentConfig/RenewPassword";
import { useUser } from "../../Context/User";
import { RestorePswProvider, useRestorePsw } from "./store";

const RestorePsw = ()=>(
    <RestorePswProvider>
        <div style={{width:"100%"}}>
            <Header />
            <div className="d-flex justify-content-center">
                <span style={{textAlign:"right",color:"#fff"}}>
                    Restablecer Contraseña
                </span>
            </div>
            <RestorePswTunnel />
        </div>
    </RestorePswProvider>
)
export default RestorePsw;
const RestorePswTunnel = ()=>{
    const {user} = useUser();
    const {configData,configLoad,configError} = useRestorePsw();
    if(user){
        if(configLoad)return <Load isVisible={true} />
        if(configData)return <ContentConfig />
        if(configError)return <ErrorContainer err={configError} />
        return null;
    }else{
        return <ErrorContainer err={"Debes iniciar sesión para visualizar este contenido."} />
    }
}