import React,{useState} from "react";
import {useModal} from "../../../Modal/Context";
import {Container,Row,Col,Card} from "react-bootstrap";
import CardElement from "./CardElement";
import { useMyConfig } from "../../../../../Screens/MyConfig/store";
import { colors } from "../../../../../Utils/Constants";
import { useMyPaymethods } from "../../../../../Screens/MyPaymethods/store";
const PayMethods = (props)=>{
    const {configData} = useMyPaymethods();
    const {paymethods} = configData;

    return(
        <div className="container">
                    {
                        paymethods.length>0 ? 
                        <Row>
                            {
                                paymethods.map((item,index)=>(
                                    <CardElement key={index} element={item} />
                                ))
                            }
                        </Row> : 
                        <div className="p-5 d-flex justify-content-center">
                            <span style={{fontSize:20,fontWeight:"bold",color:colors.secondary}}>
                                Aún no tienes métodos de pagos registrados.
                            </span>
                        </div>
                    }
        </div>
    )
}
export default PayMethods;