import React,{useState,useEffect} from 'react';
import { useUser } from '../../../../../../Context/User';
import { REQUEST } from '../../../../../../Utils/Constants';
import { Toast } from '../../../../../../Utils/Toast';
import { useTickets } from '../Context';
const Confirmation = React.createContext();
export const ConfirmationProvider = (props)=>{
    const {buyTickets,loaderPayment,closeModal,total,price,area,event} = props;
    const {data,DIR} = useTickets();
    const {valor,functionDate,id_price} = data;
    const {selectedDate,tickets,user,selectedFunction,selectedArea} = useUser();
    //estados de actualizacion de imagen
    const [needImg,setNeedImg] = useState(null);
    //estados de aceptación de terminos de uso
    const [terminos1,setTerminos1] = useState(false);
    const [terminos2,setTerminos2] = useState(false);
    const [terminos3,setTerminos3] = useState(false);

    const [terminos4,setTerminos4] = useState(false);
    //estados para el componente Mercado Pago
    const [mpParams,setmpParams] = useState(null);
    const [mpPagado,setmpPagado] = useState(false);
    //estados para el componente Stripe Checkout
    const [clientSecret,setClientSecret] = useState(null);
    //Estados principales 
    const [error,setError] = useState(null);
    const [load,setLoad] = useState(false);
    const handleLoad = (arg)=>setLoad(arg);

    //Funcion para Verificar y actualizar el estatus de mercado pago
    const refreshMpEstatus = async()=>{
        let r = await REQUEST({
            url:"",
            postData:{
                type:"getPreferenceStatus",
                id:mpParams.PREFERENCE.ID
            }
        })
        if(r){
            if(r.error){
                //console.log("Error en la verificación de la preferencia:",r.error);
            }else{
                let newStatus = r.result;
                //console.log("ESTATUS",newStatus);
                switch (newStatus.ESTATUS_ID) {
                    case 2:
                        setmpPagado(true);
                        buyTickets(newStatus.id_preference)
                        closeModal();
                        break;
                    case 3:
                        setmpPagado(false);
                        closeModal();
                        Toast("Pago declinado","error");
                        cleanUserTickets(newStatus.id_preference);
                        break;

                    default:
                        
                        break;
                }
            }
        }
    }
    const cleanUserTickets = async(id)=>{
        let r = await REQUEST({
            url:"",
            postData:{
                type:"updateUserTickets",
                user:user.id_usuario
            }
        })
        if(r){
            if(r.error){
                Toast(r.error,"error");
            }else{
                window.location.href = `/payment-proccess?id=${id}`
            }
        }
    }
    //Funcion para verificar si el usuario tiene imagen y obtener los parámetros de mercado pago
    const refreshNeedImg = async()=>{
        let r = await REQUEST({
            url:"",
            postData:{
                type:"getUserINE",
                user:user.id_usuario,
                id_function:selectedDate,
                tickets,
                total,
                event:selectedFunction.id_clave,
                funcion:selectedDate,
                area:selectedArea,
                price:valor,
                id_price,
                functionDate,
            },
            handleToggleLoad:handleLoad
        })
        //console.log(r);
        if(r){
            if(r.error){
                setError(r.error);
            }else{
                setNeedImg(r.result.INE);
                setmpParams(r.result.MERCADOPAGOPARAMS);
                setClientSecret(r.result.clientSecret);
            }
        }
    }
    useEffect(()=>{
        if(needImg===null){
            refreshNeedImg();
        }
    },[true])
    const value = React.useMemo(()=>{
        return({
           needImg,
           load,
           error,
           buyTickets,
           loaderPayment,
           closeModal,
           terminos1,setTerminos1,
           terminos2,setTerminos2,
           terminos3,setTerminos3,
           terminos4,setTerminos4,
           refreshNeedImg,
           mpParams,
           mpPagado,
           refreshMpEstatus,
           clientSecret
        })
    },[needImg,load,error,terminos1,terminos2,terminos3,terminos4,mpParams,mpPagado,loaderPayment,clientSecret])
    return <Confirmation.Provider value={value} {...props}/>
}
export const useConfirmation = ()=> React.useContext(Confirmation);