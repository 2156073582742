import React,{useState} from "react";
import ContextComponent from "../../../Context/Component";
import Content from "./BodyContent";
import FadeIn from 'react-fade-in';

const Component = (props)=>{
    const principalBodyStyle={
        maxHeight:400,
        overflowY:"auto",
    }
    return(
        <>
            <div style={principalBodyStyle}>
                <FadeIn transitionDuration={800}>
                    <ContextComponent Content={()=><Content />} url="" data_start={{type:"getEvents",f_date:new Date()}} />
                </FadeIn>
            </div>
        </>
    )
}

export default Component;