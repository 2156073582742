import React,{useState,Fragment} from "react";
import {Row,Col,Card} from "react-bootstrap";
import Input from "../../../Input";
import IconButton from "../../../IconButton";
import {useUser} from "../../../../../Context/User";
import {REQUEST} from "../../../../../Utils/Constants";
import {Toast} from "../../../../../Utils/Toast";
import md5 from "md5";
import { useModal } from "../../../Modal/Context";
import { useRestorePsw } from "../../../../../Screens/RestorePsw/store";
const RenewPassword = (props)=>{
    const {configData,refreshMyConfig} = useRestorePsw();
    const {estados,estado} = configData;
    const [old_psw,setold_psw] = useState("");
    const [new_psw,setnew_psw] = useState("");
    const [new_psw_repeat,setnew_psw_repeat] = useState("");
    const [selectedEstado] = useState(estado);
    const [load,setLoad] = useState(false);
    const {user} = useUser();
    const updateEstado = async(id_estado)=>{
        let r = await REQUEST({
            url:"",
            postData:{
                type:"updateEstado",
                user:user.id_usuario,
                estado:id_estado
            },
            handleToggleLoad:setLoad
        })
          if(r){
            if(r.error){
                Toast(r.error,"error");
            }else{
                Toast("Su estado de residencia ha sido actualizado.","info");
                refreshMyConfig(true);
            }
        }
    }
    const renew = async()=>{
        let r = await REQUEST({
            url:"",
            postData:{
                type:"updateClientPassword",
                user:user.id_usuario,
                old_psw:md5(old_psw),
                new_psw:md5(new_psw)
            },
            handleToggleLoad:setLoad
        })
          if(r){
            if(r.error){
                Toast(r.error,"error");
            }else{
                Toast("Su contraseña se ha actualizado exitosamente.","info");
                reset();
            }
        }
    }
    const checkOldPsw = ()=>{
        if(!old_psw){
            return "Escriba su contraseña anterior."
        }else{
            if(old_psw.length>=8){
                return null;
            }else{
                return "La contraseña debe contener al menos 8 caracteres"
            }
        }
    }
    const checkNewPsw = ()=>{
        if(!new_psw){
            return "Escriba su nueva contraseña."
        }else{
            if(new_psw.length>=8){
                if(new_psw!==old_psw){
                    return null;
                }else{
                    return "La contraseña nueva debe ser diferente a la anterior."
                }
            }else{
                return "La contraseña nueva debe contener al menos 8 caracteres"
            }
        }
    }
    const checkNewPswRepeat = ()=>{
        if(!new_psw_repeat){
            return "Repita su nueva contraseña."
        }else{
            if(new_psw===new_psw_repeat){
                return null;
            }else{
                return "Las contraseñas nuevas no coinciden!."
            }
        }
    }
    const checkFormErrors = ()=>{
        if(checkOldPsw()||checkNewPsw()||checkNewPswRepeat()){
            return true;
        }else{
            return false;
        }
    }
    const reset = ()=>{
        setold_psw("");
        setnew_psw("");
        setnew_psw_repeat("");
    }
    return(
        <Fragment>
            <div className="container">
                <Row>
                    <Col xs="12" md="6">
                        <Input value={old_psw} onChange={setold_psw} errorMsg={checkOldPsw()} placeholder="Contraseña actual" label="Contraseña actual" password />
                    </Col>
                    <Col xs="12" md="6">
                        <Input value={new_psw} onChange={setnew_psw} errorMsg={checkNewPsw()} placeholder="Contraseña nueva" label="Contraseña nueva" password />
                    </Col>
                    <Col xs="12" md="6">
                        <Input value={new_psw_repeat} onChange={setnew_psw_repeat} errorMsg={checkNewPswRepeat()} placeholder="Repetir contraseña nueva" label="Repetir contraseña nueva" password />
                    </Col>
                    <Col xs="12" md="6">
                        <div className="d-flex justify-content-end">
                            <div style={{display:"flex",flexDirection:"row"}}>
                                <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:15,marginRight:15}}>
                                    <IconButton type="danger" execute={reset} title="Reiniciar formulario" icon="FaSyncAlt" round/>
                                </div>
                                <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:15}}>
                                    <IconButton type="info" execute={renew} loader={load} title="Restablecer contraseña" icon="FaSave" disabled={checkFormErrors()} round/>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs="12">
                    <label className="badge">
                        Estado de residencia
                    </label>
                    <select className="form-control" value={selectedEstado} onChange={(el)=>updateEstado(el.target.value)}>
                        <option value="">
                            Seleccione una estado
                        </option>
                        {
                            estados.map((item,index)=>(
                                <option key={index} value={item.value}>
                                    {
                                        item.label
                                    }
                                </option>
                            ))
                        }
                    </select>
                </Col>
                </Row>
            </div>
        </Fragment>
    )
}
export default RenewPassword;