import React, {useState} from "react";
import {Card,Col} from "react-bootstrap";
import IconButton from "../../../IconButton";
import {colors, REQUEST} from "../../../../../Utils/Constants";
import {Toast} from "../../../../../Utils/Toast";
import {useUser} from "../../../../../Context/User";
import {useModal} from "../../../Modal/Context";
import { useMyPaymethods } from "../../../../../Screens/MyPaymethods/store";
const CardElement = (props)=>{
    const {element} = props;
    const [loaderDelete,setLoaderDelete] = useState(false);
    const {user} = useUser();
    const {refreshMyConfig} = useMyPaymethods();

    const deletePayMethod = async(item)=>{
        let r = await REQUEST({
            url:"",
                postData:{
                    type:"deletePayMethod",
                    payMethod:item.id_metodo,
                    user:user.id_usuario
                },
                handleToggleLoad:setLoaderDelete
          })
          if(r){
            if(r.error){
                Toast(r.error,"error");
            }else{
                Toast("Método de pago eliminado exitosamente.","info");
                refreshMyConfig(true);
            }
          }
    }
    return(
        <Col xs="12" md="6">
            <Card style={{ width: '100%',backgroundColor:colors.primary }}>
                <Card.Body style={{display:"flex",justifyContent:"center",alignItems:"center",backgroundColor:colors.primary}}>
                    <div className="creditCardContainer hipnoBackground">
                        <div className="d-flex justify-content-end" style={{padding:10}}>
                            <span style={{color:"#fff",fontWeight:"bold",fontSize:15}}>
                                {element.nombre}
                            </span>
                        </div>
                        <div className="d-flex justify-content-center" style={{padding:10}}>
                            <span style={{color:"#c1c1c1",fontWeight:"bold",fontSize:20}}>
                                {element.numero}
                            </span>
                        </div>
                        <div className="d-flex justify-content-center" style={{padding:10}}>
                            <IconButton type="danger" execute={()=>deletePayMethod(element)} loader={loaderDelete} title="Eliminar método de pago" icon="FaSave" round/>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    )
} 

export default CardElement;