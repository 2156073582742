import Load from "../../Components/Elements/AloneLoader";
import ErrorContainer from "../../Components/Elements/ErrorContainer";
import Header from "../../Components/Elements/Header";
import ContentConfig from "../../Components/Elements/LeftBar/UserData/ModalContentConfig/CreatePayMethod";
import { useUser } from "../../Context/User";
import { CreatePaymethodProvider, useCreatePaymethod } from "./store";

const CreatePaymethod = ()=>(
    <CreatePaymethodProvider>
        <div style={{width:"100%"}}>
            <Header />
            <div className="d-flex justify-content-start">
                <span style={{textAlign:"right",color:"#fff"}}>
                    Nuevo Método de Pago
                </span>
            </div>
            <CreatePaymethodTunnel />
            <div style={{height:150}}></div>
        </div>
    </CreatePaymethodProvider>
)
export default CreatePaymethod;
const CreatePaymethodTunnel = ()=>{
    const {user} = useUser();
    const {configData,configLoad,configError} = useCreatePaymethod();
    if(user){
        if(configLoad)return <Load isVisible={true} />
        if(configData)return <ContentConfig />
        if(configError)return <ErrorContainer err={configError} />
        return null;
    }else{
        return <ErrorContainer err={"Debes iniciar sesión para visualizar este contenido."} />
    }
}