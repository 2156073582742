import React, {Fragment} from "react";
import ErrorContainer from "../../../Components/Elements/ErrorContainer";
import IconButton from "../../../Components/Elements/IconButton";
import { useScreen } from "../../../Context/Screen/store";
const Declined = ()=>{
    const {NAVTO} = useScreen();
    return(
        <Fragment>
            <div className="d-flex justify-content-center">
                <ErrorContainer 
                    error={"Lo sentimos, su pago ha sido declinado. Por favor vuelva a intentarlo."}
                    style={{marginTop:10}}
                />
            </div>
            <div className="d-flex justify-content-center p-2">
                <IconButton 
                    execute={()=>NAVTO("/")}
                    icon={"FaSyncAlt"}
                    type="success"
                    title="Volver a intentar"
                />
            </div>
        </Fragment>
    )
}
export default Declined;