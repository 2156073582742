import React, { Fragment } from "react";
import IconButton from "../../../Components/Elements/IconButton";
import { useScreen } from "../../../Context/Screen/store";
const CheckAgain = ()=>{
    const {refreshScreenData} = useScreen();
    return(
        <Fragment>
            <div className="d-flex justify-content-center">
                <IconButton 
                    execute={refreshScreenData}
                    icon={"FaSyncAlt"}
                    type="success"
                    title="Volver a consultar"
                />
            </div>
        </Fragment>
    )
}
export default CheckAgain;