import React,{useState,useEffect} from "react";
import {PriceSelectorProvider,usePriceSelector} from "./Context";
import {useUser} from "../../../../../Context/User";
import FadeIn from 'react-fade-in';
import Content from "./Content";
import AloneLoader from "../../../AloneLoader";

const PriceSelector = (props)=>{
    const {selectedArea} = useUser();
    const [functions,setFunctions] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const handleLoading = (param)=>setIsLoading(param);




    return(
        <>
            <FadeIn transitionDuration={800}>
                <PriceSelectorProvider id_clave={selectedArea}>
                    <PriceSelectorTunnel Content={()=><Content />} />
                </PriceSelectorProvider>
            </FadeIn>
        </>
    )
}
export default PriceSelector;

const PriceSelectorTunnel = (props)=>{
    const {Content} = props;
    const {isLoading} = usePriceSelector();
    return(
        <>
            {
                isLoading ? <AloneLoader isVisible={true} size={100} /> : <Content />
            }
        </>
    )
}