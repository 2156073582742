import Load from "../../Components/Elements/AloneLoader";
import ErrorContainer from "../../Components/Elements/ErrorContainer";
import Header from "../../Components/Elements/Header";
import ContentConfig from "../../Components/Elements/LeftBar/UserData/ModalContentConfig/MyShoping";
import { useUser } from "../../Context/User";
import { MyShoppingProvider, useMyShopping } from "./store";

const MyShopping = ()=>(
    <MyShoppingProvider>
        <div style={{width:"100%"}}>
            <Header />
            <div className="d-flex justify-content-start">
                <span style={{textAlign:"right",color:"#fff"}}>
                    Mis Compras
                </span>
            </div>
            <MyShoppingTunnel />
            <div style={{height:150}}></div>
        </div>
    </MyShoppingProvider>
)
export default MyShopping;
const MyShoppingTunnel = ()=>{
    const {user} = useUser();
    const {configData,configLoad,configError} = useMyShopping();
    if(user!=="i"){
        if(configLoad)return <Load isVisible={true} />
        if(configData)return <ContentConfig />
        if(configError)return <ErrorContainer err={configError} />
        return null;
    }else{
        return <ErrorContainer err={"Debes iniciar sesión para visualizar este contenido."} />
    }
}