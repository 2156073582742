import React, {useState,useMemo,useEffect} from "react";
import {REQUEST} from "../../../../../Utils/Constants";
import {Toast} from "../../../../../Utils/Toast";
import {useUser} from "../../../../../Context/User";


const PriceSelectorContext = React.createContext();

export const PriceSelectorProvider = (props)=>{
    const {id_clave} = props;
    const [prices,setPrices] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const handleLoading = (param)=>setIsLoading(param);
    const {selectedDate} = useUser();

    const getData = async()=>{
        handleLoading(true);
        let postData = {type:"getPrices",id_clave,id_funcion:selectedDate};
        let r = await REQUEST({url:"",postData}).catch(e=>{
          Toast("Error al conectar al servidor","error");
          handleLoading(false);
        })
        if(r){
          if(r.error){
            Toast(r.error,"error");
          }else{
            setPrices(r.result.prices)
          }
        }
        handleLoading(false);
    }

    useEffect(()=>{
        getData();
    },[id_clave])
    const value = useMemo(()=>{
        return({
            prices,
            getData,
            isLoading
        })
    },[prices,isLoading])
    return <PriceSelectorContext.Provider value={value} {...props}/>
}


export const usePriceSelector = ()=>{
  return React.useContext(PriceSelectorContext);
}
