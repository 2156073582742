
const LeftSide = ()=>{
    const URLIMGLOGOWHITE = `${process.env.PUBLIC_URL}/img/john_milton_logo_white.png`;
    return(
        <div 
            style={{
                display:"flex",
                alignItems:"center",
                justifyContent:"flex-end"
            }}
        >
            <img src={URLIMGLOGOWHITE} alt="JM-LOGO" style={{width:90,marginRight:15}} />
        </div>
    )
}
export default LeftSide;