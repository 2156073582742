import React from "react";
import ErrorContainer from "../../../../ErrorContainer";
import AloneLoader from "../../../../AloneLoader";
import ImageSelector from "./ImageSelector";
import { ConfirmationProvider, useConfirmation } from "./Store";
import { useTickets } from "../Context";
import MercadoPagoForm from "./MercadoPagoForm";
import Terminos from "./Terminos";

const ModalConfirmation = (props)=>{

    return(
        <ConfirmationProvider {...props} >
            <ConfirmationTunnel />
        </ConfirmationProvider>
    )
}
export default ModalConfirmation;
const ConfirmationTunnel = ()=>{
    const {needImg,load,error,mpParams,mpPagado} = useConfirmation();
    const {data} = useTickets();
    const {API_TYPE} = data;
    //Si esta cargando la información
    if(load){
        return <AloneLoader isVisible={true} size={100} />
    }
    //Si ocurrio algun error
    if(error){
        return <ErrorContainer error={error} />
    }
    //Si el usuario no ha configurado una imágen
    if(needImg){
        return <ImageSelector />
    }
    //Si el tipo de pago es mercado pago y si no se ha pagado aún
    if(API_TYPE===2&&mpParams&&!mpPagado){
        return <MercadoPagoForm />
    }else{
        //Validación de terminos y condiciones y boton comprar
        return <Terminos />
    }

}