import React,{useState} from "react";
import {Container,Row,Col} from "react-bootstrap";
import Input from "./Input";
import {colors,encryptText,REQUEST} from "../../Utils/Constants";
import IconButton from "./IconButton";
import {useUser} from "../../Context/User";
import {Toast} from "../../Utils/Toast";
import {useModal} from "./Modal/Context";
import { useCreatePaymethod } from "../../Screens/CreatePaymethod/store";

const Content = (props)=>{
    const {user} = useUser();
    const {refreshMyConfig} = useCreatePaymethod()
    const [number,setNumber] = useState("");
    const [name,setName] = useState("");
    const [expiry_month,setExpiry_month] = useState("01");
    const [expiry_year,setExpiry_year] = useState(get_ini_year());
    const months_items = expiry_month_items();
    const years_items = expiry_year_items(get_ini_year(),15);
    const [loaderSave,setLoaderSave] = useState(false);
    const returnMask = (str)=>{
        let arrayStr = Array.from(str.replace(/-/g,""));
        let newStr = "";
        for (var i = 0; i < arrayStr.length; i++) {
          if(!isNaN(arrayStr[i])){
            if(i<16){
              newStr += arrayStr[i];
              if(arrayStr[i+1]){
                if((i+1)%4===0){
                  if(i!==15){
                    newStr += "-";
                  }
                }
              }
            }
          }
        }
        setNumber(newStr);
    }
    const selectStyle = {
        width:"100%",
        height: 40,
        borderRadius:5,
        borderStyle:"solid",
        borderWidth:1,
        borderColor:colors.primary,
        backgroundColor:colors.secondary,
        color:"#fff",
        fontWeight:"bold",
        fontSize:20
      }
    const savePayMethod = async()=>{
      let r = await REQUEST({
        url:"",
            postData:{
                type:"saveNewPayMethod",
                card:{
                  number: encryptText(number.replace(/-/,"")),
                  expiry: encryptText(expiry_month) + "/" + encryptText(expiry_year),
                  name
                },
                user:user.id_usuario
            },
            handleToggleLoad:setLoaderSave
      })
      if(r){
        if(r.error){
            Toast(r.error,"error");
        }else{
            reset();
            Toast("Método de pago creado exitosamente.","success");
            refreshMyConfig(true);
        }
      }
    }
    const reset = ()=>{
      setNumber("");
      setExpiry_month("01");
      setExpiry_year(get_ini_year());
      setName("");
    }
    const checkNumber = ()=>{
      if(number.length==19){
        return null;
      }else{
        return "número de tarjeta debe contener 16 dígitos";
      }
    }
    const checkName = ()=>{
      if(!name){
        return "Nombre completo de la tarjeta."
      }else{
        return null;
      }
    }
    const checkErrors = ()=>{
      if(!checkName()&&!checkNumber()){
        return false;
      }else{
        return true;
      }
    }
    return(
        <Container>
            <Row>
                <Col xs={12} md={6}>
                    <Input value={number} placeholder="Número de tarjeta" label="Número" onChange={returnMask} errorMsg={checkNumber()} />
                </Col>    
                <Col xs={12} md={6}>
                    <Input value={name} placeholder="Nombre del titular" label="Nombre completo" onChange={setName} errorMsg={checkName()}/>
                </Col>   
                <Col xs={12} md={6}>
                  <div>
                      <p style={{fontSize:10,fontWeight:"bold"}}>
                      Mes
                      </p>
                  </div>
                  <select className="form-control" style={selectStyle} value={expiry_month} onChange={e=>setExpiry_month(e.target.value)}>
                      {
                      months_items.map(item=>(
                          <option value={item} key={item}>
                          {item}
                          </option>
                      ))
                      }
                  </select>
                </Col> 
                <Col xs={12} md={6}>
                  <div>
                      <p style={{fontSize:10,fontWeight:"bold"}}>
                      Año
                      </p>
                  </div>
                  <select className="form-control" style={selectStyle} value={expiry_year} onChange={e=>setExpiry_year(e.target.value)}>
                      {
                      years_items.map(item=>(
                          <option value={item} key={item}>
                          {item}
                          </option>
                      ))
                      }
                  </select>
                </Col>
                <Col xs={12}>
                      <div className="d-flex justify-content-end" style={{padding:20}}>
                        <div style={{display:"flex",flexDirection:"row"}}>
                          <div style={{marginLeft:20}}>
                            <IconButton type="info" execute={reset} title="Reiniciar formulário" icon="FaSyncAlt" round/>
                          </div>
                          <div style={{marginLeft:20}}>
                            <IconButton type="success" execute={savePayMethod} loader={loaderSave} title="Guardar método de pago" icon="FaSave" disabled={checkErrors()} round/>
                          </div>
                        </div>
                      </div>
                </Col>
            </Row>
        </Container>
    )
}
export default Content;
const expiry_month_items = ()=>{
    return [
      "01","02","03","04","05","06","07","08","09","10","11","12"
    ]
  }
  const get_ini_year = ()=>{
    let date = new Date();
    let year =  date.getFullYear().toString();
    return year.slice(2,4);
  }
  const expiry_year_items = (ini_year,times)=>{
    let ini = parseInt(ini_year);
    let items = [];
    for (var i = 0; i < (times); i++) {
      items.push(returnStringNum(ini+(i)))
    }
    return items;
  }
  const returnStringNum = (num)=>{
    if(num>=10){
      return num.toString();
    }else{
      return "0"+num;
    }
  }