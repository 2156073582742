/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { REQUEST } from '../../../Utils/Constants';
import { useUser } from '../../../Context/User';
import moment from "moment";
const HomeContext = React.createContext();
export const HomeContextProvider = (props)=>{
    const {production} = props;
    const {user,modifyFunction} = useUser();
    //HOME STATES
    const [HomeData,setHomeData] = React.useState(null);
    const [HomeLoader,setHomeLoader] = React.useState(false);
    const [HomeError,setHomeError] = React.useState(null);
    const HomeHandleLoad = (arg)=> setHomeLoader(arg);
    const [search,setSearch] = React.useState("");
    const [events,setEvents] = React.useState([]);
    //HEADER STATES
    const [HeaderText,UpdateHeaderText] = React.useState("Home");
    //HOME METHODS
    const SearchHomeData = async()=>{
        let r = await REQUEST({
            url:"api/v1/GetHomeData",
            postData:null,
            handleToggleLoad:HomeHandleLoad
        })
        if(r.error)setHomeError(r.error);
        if(r.result.HomeData){
            setHomeData(r.result.HomeData)
            //console.log("Se seteo HomeData")
        };
        //setTimeout(()=>console.log({HomeData,HomeError,HomeLoader,HomeDataResponse:r.result.HomeData}),1000);
    }
    const getEvents = ()=>{
        if(HomeData&&HomeData.events.length>0){
            return HomeData.events
            //if(!search)return HomeData.events;
            //let srch = search.toLowerCase();
            //return HomeData.events.filter((ev)=>( ev.estado.toLowerCase().indexOf(srch)!==-1 ) || ( ev.ciudad.toLowerCase().indexOf(srch)!==-1 ));
        }else{
            return [];
        }
    }
    const GETINIDATA = ()=>!HomeData&&!HomeError?SearchHomeData():null;
    const UPDATEEVENTS = ()=>setEvents(getEvents());
    React.useEffect(GETINIDATA,[]);
    React.useEffect(UPDATEEVENTS,[search,HomeData])
    const value = React.useMemo(()=>{
        return({
            HomeData,
            HomeLoader,
            HomeError,
            HeaderText,
            search,
            setSearch,
            HomeHandleLoad,
            SearchHomeData,
            UpdateHeaderText,
            getEvents,
            events,
        })
    },[
        HomeData,
        HomeLoader,
        HomeError,
        HeaderText,
        search,
        events,
    ])
    return <HomeContext.Provider value={value} {...props}/>
}
export const useHomeContext = ()=> React.useContext(HomeContext);