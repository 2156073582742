import React from "react";
import { colors, isWeb } from "../../../../../Utils/Constants";
import { useAreaSelector } from "./Context";
import { useUser } from "../../../../../Context/User";

const ListHorizontal = (props)=>{
    const {areas} = useAreaSelector();
    const {selectedArea,modifyArea} = useUser();
    const principalStyle = {
        width:"100%",
        paddingLeft:5,
        paddingRight:5,
        paddingTop:10,
        paddingBottom:10,
        overflowX:"scroll",
        alignItems:"center",
        display:"flex",
        flexDirection:"row",
        marginTop:20
    }
    const dateStyleContainer = {
        minWidth:130,
        height:130,
        borderStyle:"solid",
        borderWidth:1,
        borderRadius:10,
        marginLeft:10,
        cursor:"pointer",
        backgroundColor:""
    }

    return(
        <div style={principalStyle} className={isWeb()?"d-flex justify-content-center":""}>
                {
                    areas.map((item,index)=>(
                        <div key={index} onClick={()=>modifyArea(item.id_clave)} style={{...dateStyleContainer,backgroundColor:item.id_clave===selectedArea?colors.secondary:colors.bgDark}} className="minisizeable containerDate">
                            <div style={{width:"100%",height:"100%"}} className="alineado">
                                <div className="text-center">
                                    <span style={{color:item.id_clave===selectedArea?"#fff":colors.secondary,fontWeight:"bold",fontSize:12}}>
                                        {item.descrip}
                                    </span><br></br>
                                </div>
                            </div>    
                        </div>
                    ))
                }
        </div>
    )
}
export default ListHorizontal;