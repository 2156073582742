import {Fragment} from "react";
import {Grid,MenuList,Menu,MenuItem,ListItemIcon,ListItemText} from "@mui/material";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
import { colors } from "../../../Utils/Constants";
import { useUser } from "../../../Context/User";
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginModal from "../LoginModal";
import { FadeIn } from "react-slide-fade-in";
const Header = ()=>{
    const {
        user,
        isOpenedMenuList,
        menuListOption,
        setMenuListOption
    } = useUser();
    return(
        <FadeIn transitionDuration={800}>
            <Grid container style={{backgroundColor:colors.bgGrayDark}}>
                <Grid item xs={2} style={{padding:3}}>
                    <RightSide />
                </Grid>
                <Grid item xs={10} style={{padding:3}}>
                    <LeftSide />
                    <Menu
                        id="basic-menu"
                        anchorEl={menuListOption}
                        open={isOpenedMenuList}
                        onClose={()=>setMenuListOption(false)}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            style:{
                                width:"100%",
                                backgroundColor:colors.bgGrayDark,
                                borderStyle:"solid",
                                borderColor:colors.primary
                            }
                        }}
                        sx={{
                            width:"100%",
                            p:0
                        }}
                    >
                        <MenuList sx={{minWidth:200}}>
                            <RightMenuContent user={user}/>
                        </MenuList>
                    </Menu>
                </Grid>
            </Grid> 
            <LoginModal />
        </FadeIn>
    )
}
const RightMenuContent = ()=>{
    const {
        handleToogleModalLogin,
        user,
        logout,
        setMenuListOption
    } = useUser();
    const onPressLogin = (e)=>{
        handleToogleModalLogin(e);
        setMenuListOption(false);
    };
    const onPressLogout = ()=>{
        logout();
        setMenuListOption(false);
    }
    if(user){
        return(
            <Fragment>
                <MenuItem onClick={onPressLogout}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" style={{color:colors.primary}} />
                    </ListItemIcon>
                    <ListItemText style={{color:colors.secondary}}>Salir</ListItemText>
                </MenuItem>
            </Fragment>
        )
    }
    return(
        <Fragment>
            <MenuItem onClick={onPressLogin}>
                <ListItemIcon>
                    <LoginIcon fontSize="small" style={{color:colors.primary}} />
                </ListItemIcon>
                <ListItemText style={{color:colors.secondary}}>Identificarme</ListItemText>
            </MenuItem>
        </Fragment>
    )
}
export default Header;