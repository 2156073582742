/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { REQUEST } from '../../../../Utils/Constants';
import {Toast} from "../../../../Utils/Toast";
import md5 from "md5";
import {useUser} from "../../../../Context/User";
const LoginContext = React.createContext();
export const LoginContextProvider = (props)=>{
    const {func,registerUser,modalLogin,cancel} = props;
    const [LoginData,setLoginData] = React.useState(null);
    const [LoginError,setLoginError] = React.useState(null);
    const [LoginDataLoader,setLoginDataLoader] = React.useState(false);
    const handleLoadLoginData = (arg)=>setLoginDataLoader(arg);

    //Login
    const [email,setEmail] = React.useState("");
    const [psw,setPsw] = React.useState("");
    const {setUserData} = useUser();
    const [load,setLoad] = React.useState(false);
    const handleToggleLoad = (param)=>setLoad(param);
    const [email_r,setEmail_r] = React.useState("");
    const checkPsw = ()=>{
        if(psw){
            if(psw.length>=8){
                return null;
            }else{
                return "La contraseña debe contener al menos 8 caracteres.";
            }
        }else{
            return "Ingrese su contraseña.";
        }
    }
    const checkMail = (param)=>{
        if(param){
            if(email_r){
                let reg = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
                if (reg.test(email_r)) {
                  return null;
                }else{
                  return "Formato de correo electrónico inválido";
                }
            }else{
                return "Ingrese su correo electrónico."
            }
        }else{
            if(email){
                let reg = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
                if (reg.test(email)) {
                  return null;
                }else{
                  return "Formato de correo electrónico inválido";
                }
            }else{
                return "Ingrese su correo electrónico."
            }
        }
    }
    const loginApp = async()=>{
        let r = await REQUEST({
            url:"",
            postData:{
                type:"login",
                form:{
                    email:email.trim(),
                    psw:md5(psw)
                }
            },
            handleToggleLoad
        }).catch(e=>{
            Toast("Error al conectar al servidor","error");
        })
        if(r){
            if(r.error){
                Toast(r.error,"error");
            }else{
                cancel()
                setUserData({
                    data:r.result.userData,
                    token:r.result.token
                })
            }
        }
    }
    const recuperatePassword = async()=>{
        let r = await REQUEST({
            url:"",
            postData:{
                type:"renewPassword",
                form:{
                    email:email_r.trim()
                }
            },
            handleToggleLoad
        }).catch(e=>{
            Toast("Error al conectar al servidor","error");
        })
        if(r){
            if(r.error){
                Toast(r.error,"error");
            }else{
                Toast(r.result.response_msg,"info");
                //console.log(r)
            }
        }
    }
    const checkFormErrors = ()=>{
        if(!checkPsw()&&!checkMail()){
            return false;
        }else{
            return true;
        }
    }
    const checkFormErrors2 = ()=>{
        if(!checkMail(true)){
            return false;
        }else{
            return true;
        }
    }
    //REGISTER
    const [psw1,setPsw1] = React.useState("");
    const [psw2,setPsw2] = React.useState("");
    const [names,setNames] = React.useState("");
    const [second_names,setSecond_names] = React.useState("");
    const [telefono,setTelefono] = React.useState("");
    const [loadRegister,setLoadRegister] = React.useState(false);
    const [selectedEstado,setSelectedEstado] = React.useState(null);
    const handleToggleLoadRegister = (param)=>setLoadRegister(param);
    const [terminos,setTerminos] = React.useState(false);

    const checkPsw1 = ()=>{
        if(psw1){
            if(psw1.length>=8){
                return null;
            }else{
                return "La contraseña debe contener al menos 8 caracteres.";
            }
        }else{
            return "Ingrese su contraseña.";
        }
    }

    const checkPsw2 = ()=>{
        if(psw2){
            if(psw2===psw){
                return null;
            }else{
                return "Las contraseñas no coinciden.";
            }
        }else{
            return "Repita su contraseña.";
        }
    }
    let checkNames = ()=>{
        if(names&&names.length>4){
            return null;
        }else{
            return "El nombre debe contener al menos 4 caracteres"
        }
    }
    let checkSecondNames = ()=>{
        if(second_names&&second_names.length>4){
            return null;
        }else{
            return "El apellido debe contener al menos 4 caracteres."
        }
    }
    const reset = ()=>{
        setNames("");
        setSecond_names("");
        setPsw("");
        setPsw2("");
        setEmail("");
        setTelefono("");
        setSelectedEstado(null);
    }
    const getErrors = ()=>{
        let errors = [];
        if(checkNames()){
            errors.push(checkNames())
        }
        if(checkSecondNames()){
            errors.push(checkSecondNames());
        }
        if(checkTelefono()){
            errors.push(checkTelefono());
        }
        if(!selectedEstado){
            errors.push("Seleccione un estado para continuar.")
        }
        if(checkMail()){
            errors.push(checkMail());
        }
        if(checkPsw()){
            errors.push(checkPsw());
        }
        if(checkPsw2()){
            errors.push(checkPsw2());
        }
        if(!terminos){
            errors.push("Por favor acepte los términos y condiciones para continuar.")
        }
        return errors;
    }
    const checkFormErrorsRegister = ()=>{
        let errors = getErrors();
        //console.log({errors})
        if(errors.length>0)return false;
        return true;
    }
    const register = async ()=>{
        let r = await REQUEST({
            url:"",
            postData:{
                type:"register",
                form:{
                    email:email.trim(),
                    psw:md5(psw),
                    names:names.trim(),
                    second_names:second_names.trim(),
                    estado:selectedEstado,
                    telefono
                }
            },
            handleToggleLoadRegister
        })
        if(r){
            if(r.error){
                Toast(r.error,"error");
            }else{
                setUserData({
                    data:r.result.userData,
                    token:r.result.token
                })
            }
        }
    }
    const checkTelefono = ()=>{
        if(telefono){
            if(!isNaN(telefono)){
                if(telefono.length===10){
                    return false;
                }else{
                    return "Inserte al menos 10 digitos";
                }
            }else{
                return "Formato telefónico inválido";
            }
        }else{
            return "Telefono (10 Digitos)";
        }
    }
    const GetLoginData = async()=>{
        let r = await REQUEST({
            url:"api/v1/GetLoginData",
            postData:null,
            handleToggleLoad:handleLoadLoginData
        })
        if(r.error)setLoginError(r.error);
        if(r.result&&r.result.LoginData)setLoginData(r.result.LoginData);
        //setTimeout(()=>console.log({LoginData,LoginError,r}),1000)
    }
    const GETINIDATA = ()=>(!LoginData&&!LoginError)?GetLoginData():null;
    React.useEffect(GETINIDATA,[]);
    const value = React.useMemo(()=>{
        return({
            LoginData,
            LoginError,
            LoginDataLoader,
            registerUser,
            func,
            load,
            email,setEmail,checkMail,
            psw,
            psw1,setPsw,checkPsw,
            email_r,setEmail_r,
            psw1,setPsw1,checkPsw1,
            psw2,setPsw2,checkPsw2,
            names,setNames,checkNames,
            second_names,setSecond_names,checkSecondNames,
            loadRegister,setLoadRegister,
            selectedEstado,setSelectedEstado,
            terminos,setTerminos,
            loginApp,
            recuperatePassword,
            checkFormErrors,
            checkFormErrors2,
            cancel,
            reset,
            getErrors,
            checkFormErrorsRegister,
            register,
            telefono,setTelefono,checkTelefono
        })
    },[
        LoginData,
        LoginError,
        LoginDataLoader,
        registerUser,
        email,
        psw,
        load,
        email_r,
        psw1,
        psw2,
        names,
        second_names,
        loadRegister,
        selectedEstado,
        terminos,telefono
    ])
    return <LoginContext.Provider value={value} {...props}/>
}
export const useLoginContext = ()=> React.useContext(LoginContext);