import { Fragment } from "react";
import { Row,Col } from "react-bootstrap";
import { useUser } from "../../../Context/User"
import { colors } from "../../../Utils/Constants";
import ReturnIcon from "../ReturnIcon";
import { useNavigate,useMatch } from 'react-router-dom';
import "./styles.css";
import { FadeIn } from "react-slide-fade-in";
const FootBar = ()=>{
    const {user} = useUser();
    const navigate = useNavigate();
    const isHomeRoute = useMatch("/");
    const isConfigRoute = useMatch("/MyConfig");
    const isMyShopRoute = useMatch("/MyShopping");
    if(user&&user.first_name)return(
        <div className="jm-footbar" style={{color:colors.bgGrayDark}}>
            <FadeIn transitionDuration={800}>
                <Row style={{height:"100%"}}>
                    <Col xs="3">
                        <FootBarLabel 
                            iconName="FaTheaterMasks"
                            label="Boletos"
                            selected={isHomeRoute}
                            onPress={()=>navigate("/")}
                        />
                    </Col>
                    <Col xs="3">
                        <FootBarLabel 
                            iconName="FaUserCog"
                            label="Configuración"
                            selected={isConfigRoute}
                            onPress={()=>navigate("/MyConfig")}
                        />
                    </Col>
                    <Col xs="3">
                        <FootBarLabel 
                            iconName="AiFillShopping"
                            label="Mis Compras"
                            selected={isMyShopRoute}
                            onPress={()=>navigate("/MyShopping")}
                        />
                    </Col>
                    <Col xs="3">
                        <FootBarLabel 
                            iconName="FaListAlt"
                            label="Rifas"
                            selected={false}
                            onPress={()=>alert("Contenido en desarrollo")}
                        />
                    </Col>
                </Row>
            </FadeIn>
        </div>
    );
    return null;
}
export default FootBar;
const FootBarLabel = ({iconName,label,selected,onPress})=>{

    return(<div className="jm-footbar-label-container" onClick={onPress} style={{backgroundColor:selected?colors.gray:"transparent"}}>
        <div className="jm-footbar-label-element">
            <ReturnIcon nameIcon={iconName} color={selected?colors.secondary:colors.gray} fontSize={25} />
            {
                window.screen.availWidth >= 600 ?
                <span className="jm-footbar-label" style={{color:selected?colors.secondary:colors.gray}}>
                    {label}
                </span> : null
            }
        </div>
    </div>);
}