import React from 'react';
import { useUser } from '../../Context/User';
import { REQUEST } from '../../Utils/Constants';
const CreatePaymethod = React.createContext();
export const CreatePaymethodProvider = (props)=>{
    //const {} = props;
    const {user} = useUser();
    const [configData,setConfigData] = React.useState(null);
    const [configLoad,setConfigLoad] = React.useState(false);
    const [configError,setConfigError] = React.useState(null);
    const handleToggleLoad = (arg)=>setConfigLoad(arg);
    const refreshMyConfig = async()=>{
        let r = await REQUEST({
            url:"",
            postData:{
                type:"getConfigData",
                user
            },
            handleToggleLoad
        })
        if(r.error){
            setConfigError(r.error);
        }else{
            setConfigData(r.result);
        }
    }
    React.useEffect(()=>{
        if(!configData&&!configLoad&&user)refreshMyConfig();
    },[configData,user])
    const value = React.useMemo(()=>{
        return({
            configData,
            configLoad,
            configError,
            refreshMyConfig
        })
    },[
        configData,
        configLoad,
        configError
    ])
    return <CreatePaymethod.Provider value={value} {...props}/>
}
export const useCreatePaymethod = ()=> React.useContext(CreatePaymethod);