import React from 'react';
import { useUser } from '../../../Context/User';
import { REQUEST } from '../../../Utils/Constants';
const TicketsViewer = React.createContext();
export const TicketsViewerProvider = (props)=>{
    const {id_preference,id_intent} = props;
    const [ticketsData,setTicketsData] = React.useState(null);
    const [ticketsLoad,setTicketsLoad] = React.useState(false);
    const [ticketsError,setTicketsError] = React.useState(null);
    
    const [newTicketsLoad,setNewTicketsLoad] = React.useState(false);


    const handleTicketsLoad = (arg)=>setTicketsLoad(arg);
    const handleNewTicketsLoad = (arg)=>setNewTicketsLoad(arg);


    const {user} = useUser();
    const [counter,setCounter] = React.useState(0);

    const refreshTicketsData = async()=>{
        let r = await REQUEST({
            url:"payment",
            postData:{
                type:"getTicketsByPreference",
                user:user.id_usuario,
                id_preference,
                id_intent
            },
            handleToggleLoad:handleTicketsLoad
        })
        if(r){
            if(r.error){
                setTicketsError(r.error)
            }else{
                setTicketsData(r.result);
            }
        }
    }
    const acceptNewTickets = async(tickets)=>{
        let r = await REQUEST({
            url:"payment",
            postData:{
                type:"acceptNewTickets",
                user:user.id_usuario,
                id_preference,
                id_intent,
                tickets
            },
            handleToggleLoad:handleNewTicketsLoad
        })
        if(r){
            if(r.error){
                setTicketsError(r.error)
            }else{
                window.location.reload(true);
            }
        }
    }
    React.useEffect(()=>{
        if(!ticketsData&&!ticketsError&&counter===0){
            refreshTicketsData();
        }
    },[])
    const value = React.useMemo(()=>{
        return({
            id_preference,
            ticketsData,
            ticketsLoad,
            ticketsError,
            newTicketsLoad,
            refreshTicketsData,
            acceptNewTickets
        })
    },[ticketsData,ticketsLoad,ticketsError,newTicketsLoad])
    return <TicketsViewer.Provider value={value} {...props}/>
}
export const useTicketsViewer = ()=> React.useContext(TicketsViewer);