import React,{useRef} from "react";
import {useUser} from "../../../../../Context/User";
import IconButton from "../../../IconButton";
import { isWeb } from "../../../../../Utils/Constants";
const ButtonSearch = ()=>{
    const {searchTickets,loadingTickets,selectedPrice,tickets,ModalFunctionsRef} = useUser();
    const ExecuteBtn = ()=>{
        searchTickets(ModalFunctionsRef);
    }
    if(selectedPrice&&tickets.length===0){
        return(
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",padding:30,marginBottom:isWeb()?30:160}}>
                <IconButton type="info" execute={ExecuteBtn} loader={loadingTickets} title="Buscar Disponibilidad" icon="FaSearch" round/>
            </div>
        )
    }
    return null
}
export default ButtonSearch