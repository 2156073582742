import React from 'react';
import { REQUEST } from '../../Utils/Constants';
import { useUser } from '../User';
import { useNavigate } from 'react-router-dom';
const Screen = React.createContext();
export const ScreenProvider = (props)=>{
    const {url,post,SCREEN_NEED_USER} = props;
    const {user} = useUser();
    const NAVTO = useNavigate();
    const [screenData,setScreenData] = React.useState(null);
    const [screenError,setScreenError] = React.useState(null);
    const [screenLoad,setScreenLoad] = React.useState(false);
    const handleScreenLoad = (param)=>setScreenLoad(param);

    const refreshScreenData = async()=>{
        //console.log("Se ejecuto SCREENDATA")
        let r = await REQUEST({
            url,
            postData:{
                user:user.id_usuario,
                ...post
            },
            handleToggleLoad:handleScreenLoad,
            time:2000
        })
        //console.log({r});
        if(r){
            if(r.error){
                setScreenError(r.error);
            }else{
                setScreenData(r.result);
            }
        }
    }

    React.useEffect(()=>{
        if(url!==null){
            refreshScreenData();
        }
    },[])
    const value = React.useMemo(()=>{
        return({
            url,
            SCREEN_NEED_USER,
            screenData,
            screenError,
            screenLoad,
            refreshScreenData,
            NAVTO
        })
    },[screenData,screenError,screenLoad])
    return <Screen.Provider value={value} {...props}/>
}
export const useScreen = ()=> React.useContext(Screen);