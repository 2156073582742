import Avatar from 'react-avatar';
import { useUser } from "../../../Context/User";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
const RightSide = ()=>{
    const {user,handleClickMenuList} = useUser();
    return(
        <div
            style={{
                display:"flex",
                justifyContent:"flex-start",
                alignItems:"center"
            }}
        >
            <IconButton onClick={handleClickMenuList}>
                {
                    user && user.first_name ?
                        <Avatar name={`${user.first_name} ${user.second_name}`} round size={30} /> :
                            <MenuIcon style={{color:"#fff",fontSize:30}} />
                }
            </IconButton>
        </div>    
    )
}

export default RightSide;