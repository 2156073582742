import { Fragment } from "react";
import InputSearcher from "./InputSearcher";
import EventSelector from "./EventSelector";
import ModalFunctions from "./ModalFunctions";
import { useUser } from "../../../../Context/User";
import Header from "../../../../Components/Elements/Header";
const Body = ()=>{
    const {ModalFunctionsRef} = useUser();
    return(
        <Fragment>
            <Header />
            <EventSelector />
            <ModalFunctions />
        </Fragment>
    )
}
export default Body;