import React,{useState,useEffect} from "react";
import {DateListProvider,useDateList} from "./Context";
import {useUser} from "../../../../../Context/User";
import FadeIn from 'react-fade-in';
import Content from "./Content";
import AloneLoader from "../../../AloneLoader";

const DateListEvent = (props)=>{
    const {selectedFunction} = useUser();
    const [functions,setFunctions] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const handleLoading = (param)=>setIsLoading(param);

    if(selectedFunction)return(
        <>
            <FadeIn transitionDuration={800}>
                <DateListProvider id_clave={selectedFunction.id_clave}>
                    <DateListTunnel Content={()=><Content />} />
                </DateListProvider>
            </FadeIn>
        </>
    );
    return null;
}
export default DateListEvent;

const DateListTunnel = (props)=>{
    const {Content} = props;
    const {isLoading} = useDateList();
    return(
        <>
            {
                isLoading ? <AloneLoader isVisible={true} size={100} /> : <Content />
            }
        </>
    )
}